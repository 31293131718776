import { useEffect, useState } from 'react';
import I18n from '../../libs/i18n/I18n';
import { Col, H1, Row } from '../UI/Html';

function addLeadingZeros(value: string | string[]) {
  value = String(value);
  while (value.length < 2) {
    value = `0${value}`;
  }
  return value;
}

function calculateCountdown(endDate: string | number | Date) {
  let diff = (Date.parse(String(new Date(endDate))) - Date.parse(String(new Date()))) / 1000;

  // clear countdown when date is reached
  if (diff <= 0) return false;

  const timeLeft = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
    millisec: 0,
  };

  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = diff;

  return timeLeft;
}
interface CountDownProps {
  endDate: Date;
  callback: () => void;
}

const CountDown = ({ endDate, callback }: CountDownProps) => {
  const [countDown, setCountDown] = useState({
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const date = calculateCountdown(endDate);
      date ? setCountDown(date) : stop();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endDate]);

  const stop = () => {
    callback();
  };

  return (
    <Row className="justify-content-center">
      <Col xs="auto" className="px-1">
        <H1 className="mb-0 text-center">
          {addLeadingZeros(String((countDown as { days: number }).days))}
        </H1>
        <H1>{String(I18n.t('countDown.days'))}</H1>
      </Col>
      <Col xs="auto" className="px-1">
        <H1 className="font-weight-bold">:</H1>
      </Col>
      <Col xs='auto' className='px-1'>
        <H1 className='mb-0 text-center'>
          {addLeadingZeros(String((countDown as { hours: number }).hours))}
        </H1>
        <H1>{I18n.t('countDown.hours')}</H1>
      </Col>
      <Col xs="auto" className="px-1">
        <H1 className="font-weight-bold">:</H1>
      </Col>
      <Col xs="auto" className="px-1">
        <H1 className="mb-0 text-center">
          {addLeadingZeros(String((countDown as { min: number }).min))}
        </H1>
        <H1>{String(I18n.t('countDown.mins'))}</H1>
      </Col>
      <Col xs="auto" className="px-1">
        <H1 className="font-weight-bold">:</H1>
      </Col>
      <Col xs="auto" className="px-1">
        <H1 className="mb-0 text-center">
          {addLeadingZeros(String((countDown as { sec: number }).sec))}
        </H1>
        <H1>{String(I18n.t('countDown.secs'))}</H1>
      </Col>
    </Row>
  );
};

export default CountDown;
