import TakeEvaluation from '../../components/Projects/TakeEvaluation';
import Layout from '../../components/UI/Layout';



const TakeEvaluationContainer = () => {
  return (
    <Layout isFullWidth>
      <TakeEvaluation />
    </Layout>
  );
};

export default TakeEvaluationContainer;
