import { OthersOptions } from '@app/@types/redux/pageElement';
import { PageElement } from '@app/@types/redux/project';
import classnames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import { HiDotsVertical, HiPrinter, HiStar, HiTrash } from 'react-icons/hi';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import NoStyleDiv from '../../../../UI/Html/NoStyleDiv';
import './InboxPage.scss';

interface InboxPageProps {
  pageElement: PageElement;
}

const InboxPage: React.FC<InboxPageProps> = (props) => {
  const {
    pageElement
  } = props;

  const { id } = pageElement;

  const emails = (pageElement.attributes.options as OthersOptions).emails;

  const [tabs, setTabs] = useState(0);

  function toggleNavs(e: MouseEvent, index: number) {
    e.preventDefault();
    setTabs(index);
  }
  return (
    <Row className="mt-4 ">
      <Col md={4} className="emailList__mainContainer">
        <Nav className="emailList__nav" role="tablist" vertical>
          {emails.map((email, index) => {
            return (
              <NavItem key={`${(id)}${email.id}`}>
                <NavLink
                  aria-selected={tabs === index}
                  className={classnames('emailList__item mb-sm-3 mb-md-0', {
                    active: tabs === index,
                    first: index === 0,
                  })}
                  onClick={(e: MouseEvent) => {
                    toggleNavs(e, index);
                  }}
                  href="#pablo"
                  role="tab"
                >
                  <div className="emailList__container">
                    <div>
                      <img
                        alt={email.sender.name}
                        src={email.sender.image}
                        className="emailList__senderImage"
                      />
                    </div>
                    <div>
                      <h4 className="emailList__senderName">
                        <span>{email.sender.name}</span>
                      </h4>
                      <h5 className="emailList__subject">
                        <EllipsisText text={email.subject} length={24} />
                      </h5>
                    </div>
                  </div>
                  <div>
                    <h5 className="emailList__dateTime text-muted">
                      {moment().format('DD MMM YYYY')}
                    </h5>
                  </div>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </Col>
      <Col md={8} className="emailPage__wrapper">
        <div>
          <TabContent>
            {emails.map((email, index) => {
              if (tabs === index) {
                return (
                  <TabPane key={`${id}${email.id}`}>
                    <div className="emailPage__container">
                      <div className="emailPage__header">
                        <h5 className="emailPage__subject">{email.subject}</h5>
                        <div className="emailPage__icons">
                          <HiStar />
                          <HiPrinter />
                          <HiTrash />
                          <HiDotsVertical />
                        </div>
                      </div>
                      <div className="emailPage__header">
                        <div className="d-flex align-items-center ">
                          <img
                            alt={email.sender.name}
                            src={email.sender.image}
                            className="emailPage__senderImage"
                          />
                          <div className="">
                            <h4 className="emailPage__senderName">{email.sender.name}</h4>
                            <span className="emailPage__senderEmail text-muted ">
                              {email.sender.email}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h5 className="emailPage__dateTime  text-muted">
                            {moment().format('DD MMM YYYY')}
                          </h5>
                        </div>
                      </div>
                      <div className="emailPage__body">
                        <div className="emailPage__body__container">
                          <div className="emailPage__body__text">
                            <NoStyleDiv>{email.body}</NoStyleDiv>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                );
              }
              return null;
            })}
          </TabContent>
        </div>
      </Col>
    </Row>
  );
};

export default InboxPage;
