/* eslint-disable max-len */
import { PageElement } from '@app/@types/redux/project';
import i18n from '../../../../libs/i18n/I18n';
import { Animated, Col, Row } from '../../../UI/Html';

import { VideoOptions } from '@app/@types/redux/pageElement';
import { PAGE_ELEMENT } from '@libs/constants/constants';

interface IVideoProps {
  pageElements: PageElement[];
}

const Video: React.FC<IVideoProps> = (props) => {
  const { pageElements } = props;
  const video = pageElements.filter((x) => x.attributes.elementType === PAGE_ELEMENT.VIDEO)[0];

  const videoOptions = video.attributes.options as VideoOptions;
  const videoUrl = videoOptions.value;
  if (videoUrl) {
    if (videoUrl.includes('vimeo')) {
      return (
        <Animated>
          {videoOptions.text ? (
            <Row>
              <Col>
                <h2 className="mb-2 message__text">{videoOptions.text || ''}</h2>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col xs={12} className="d-flex align-items-center my-6">
              <>
                <div
                  style={{
                    width: '100%',
                    height: '62vh',
                  }}
                >
                  <iframe
                    title="title"
                    src={`${videoUrl}${videoUrl.includes('?') ? '&' : '?'
                      }title=0&byline=0&portrait=0&texttrack=${i18n.language}`}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    frameBorder="0"
                    allow="fullscreen"
                    allowFullScreen
                  />
                </div>
                <script src="https://player.vimeo.com/api/player.js" />
              </>
            </Col>
          </Row>
        </Animated>
      );
    }
    return (
      <Animated>
        <Row>
          <Col xs={12} className="d-flex align-items-center">
            <>
              <div
                style={{
                  padding: '56.25% 0 0 0',
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                }}
              >
                {videoUrl && (
                  <iframe
                    title="title"
                    src={`${videoUrl}?controls=1&cc_lang_pref=fr&cc_load_policy=1`}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
              </div>
            </>
          </Col>
        </Row>
      </Animated>
    );
  }
  return '';
};

export default Video;
