import { getDependeeValues, getPageElementDisabledState } from '@state/selectors/projects';
import { Input, Row } from 'reactstrap';
import { i18nCText } from '../../../../libs/i18n/I18n';

import { CheckboxAnswer } from '@app/@types/redux/answer';
import { CheckboxOptions } from '@app/@types/redux/pageElement';
import { useAppSelector } from '@state/redux/store';
import { PageElementProps } from '..';
import { Col, Label } from '../../../UI/Html';

const Checkboxes: React.FC<PageElementProps> = (props) => {

  const {
    pageElement,
    state,
    currentAnswer,
    answer,
  } = props;
  const { dependees, id } = pageElement.attributes;
  const { values } = pageElement.attributes.options as CheckboxOptions;
  const disabled = useAppSelector((state) =>
    getPageElementDisabledState(state.projects, id)
  );

  const checkbox = (value: string, index: number) => {

    const answers = currentAnswer as CheckboxAnswer;
    return (
      <div className="custom-control custom-checkbox mb-3 mt-3 text-align-initial" key={index}>
        <Input
          className="custom-control-input"
          id={`checkbox_${String(id)}_${String(index)}`}
          type="checkbox"
          disabled={disabled}
          value={value}
          checked={!!answers[index]}
          rawOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let copy = { ...answers };
            if (e.target.checked) {
              copy[index] = {
                index,
                value,
              };
            } else {
              // delete answers[index];
              copy = Object.keys(copy).reduce(
                (result: Record<string, { index: number; value: string }>, key) => {
                  if (String(key) !== String(index)) {
                    result[key] = copy[key];
                  }
                  return result;
                },
                {}
              );

            }
            answer(answers);
          }}
        />
        <Label
          className="custom-control-label checkbox__label"
          htmlFor={`checkbox_${id}_${String(index)}`}
        >
          {i18nCText(value)}
        </Label>
      </div>
    );
  };


  const options = getDependeeValues(dependees, values, state) as { value: string }[];
  return (
    <Col>
      <Row>
        <Col md="12">{options.map((item, index) => checkbox(item.value, index))}</Col>
      </Row>
    </Col>
  );
};

export default Checkboxes;
